import {Injectable} from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';

import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AuthService} from '../_services/auth.service';
import {Location} from '@angular/common';
import {HashService} from '../_services/hash.service';

@Injectable()
export class CheckHashRouteGuard implements CanActivate {
  destinationRoute;
  currRoute;
  constructor(private router: Router, private hashService: HashService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (!this.router.navigated) {
      if (route.params.hash) {
        const hashParam = route.params.hash;
        if (
          hashParam !==
          this.hashService.encryptData(
            state.url
              .replace('/' + hashParam, '')
              .trim()
              .split('#')[0] +
              (this.hashService.configs.includes('include-email')
                ? localStorage.getItem('logged-in')
                : '')
          )
        ) {
          if(hashParam !=='0000000000000000000')
          {
            this.router.navigate(['/error/error-v6']);
          }
        }
      }
    }
    return true;
  }
}
