import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateCustom',
})
export class DatePipe implements PipeTransform {
  transform(date, format: string = 'DD/MM/YYYY') {
    if (!date) {
      return 'N/A';
    }
    return moment(moment.utc(date).toDate()).format(format);
  }
}
