// Angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from '../../../core/_base/layout';
// Auth
import {AuthNoticeService} from '../../../core/auth';
import {environment} from 'environments/environment';
import {HtmlClassService} from '@app/views/theme/html-class.service';

@Component({
  selector: 'kt-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit, OnDestroy {
  // Public properties
  today: number = Date.now();
  headerLogo: string;
  pathImg: string = environment.url_image_local;

  /**
   * Component constructor
   *
   * @param el
   * @param render
   * @param layoutConfigService: LayoutConfigService
   * @param authNoticeService: authNoticeService
   * @param translationService: TranslationService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private layoutConfigService: LayoutConfigService,
    public authNoticeService: AuthNoticeService,
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    public htmlClassService: HtmlClassService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.translationService.setLanguage(
      this.translationService.getSelectedLanguage()
    );
    this.headerLogo = this.layoutConfigService.getLogo();

    //this.splashScreenService.hide();
    $('body').css({'overflow-x': 'hidden'});
  }

  ngOnDestroy(): void {
    $('body').css({'overflow-x': 'unset'});
  }

  /**
   * Load CSS for this specific page only, and destroy when navigate away
   * @param styleUrl
   */
  // private loadCSS(styleUrl: string) {
  //   return new Promise((resolve, reject) => {
  //     const styleElement = document.createElement('link');
  //     styleElement.href = styleUrl;
  //     styleElement.type = 'text/css';
  //     styleElement.rel = 'stylesheet';
  //     styleElement.onload = resolve;
  //     this.render.appendChild(this.el.nativeElement, styleElement);
  //   });
  // }
}
