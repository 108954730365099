import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormErrorService {
  reactiveForm;

  constructor() {}

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to validators name
   */
  public isControlHasError(
    form: FormBuilder | FormGroup,
    controlName: string,
    validationType: string
  ): boolean {
    this.reactiveForm = form;
    const control = this.reactiveForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
