import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(gender) {
    if (gender === null || gender == undefined) {
      return 'N/A';
    }
    return gender ? 'Nam' : 'Nữ';
  }
}
