import {Injectable, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RootSpinnerService {
  static disableSpinner = false;
  static statusChanged: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  static show() {
    if (!RootSpinnerService.disableSpinner) {
      RootSpinnerService.statusChanged.emit(true);
    }
  }

  static hide() {
    RootSpinnerService.statusChanged.emit(false);
  }
}
