export class PageConfig {
  public defaults: any = {
    dashboard: {
      page: {
        title: 'Dashboard',
        desc: 'Dashboard nhân sự',
      },
    },
    ngbootstrap: {
      accordion: {
        page: { title: 'Accordion', desc: '' },
      },
      alert: {
        page: { title: 'Alert', desc: '' },
      },
      buttons: {
        page: { title: 'Buttons', desc: '' },
      },
      carousel: {
        page: { title: 'Carousel', desc: '' },
      },
      collapse: {
        page: { title: 'Collapse', desc: '' },
      },
      datepicker: {
        page: { title: 'Datepicker', desc: '' },
      },
      dropdown: {
        page: { title: 'Dropdown', desc: '' },
      },
      modal: {
        page: { title: 'Modal', desc: '' },
      },
      pagination: {
        page: { title: 'Pagination', desc: '' },
      },
      popover: {
        page: { title: 'Popover', desc: '' },
      },
      progressbar: {
        page: { title: 'Progressbar', desc: '' },
      },
      rating: {
        page: { title: 'Rating', desc: '' },
      },
      tabs: {
        page: { title: 'Tabs', desc: '' },
      },
      timepicker: {
        page: { title: 'Timepicker', desc: '' },
      },
      tooltip: {
        page: { title: 'Tooltip', desc: '' },
      },
      typehead: {
        page: { title: 'Typehead', desc: '' },
      },
    },
    portal: {
      hr: {
        salary: {
          books: {
            page: { title: 'Lương', desc: 'Quản lý số lương' },
            create: {
              page: { desc: 'Thêm mới' },
            },
            period: {
              page: { desc: 'Danh sách kì lương' },
              calculationprocess: {
                page: { title: 'Lương', desc: 'Quy trình tính lương' },
              },
            },
          },
          confirmattendance: {
            page: { title: 'Lương', desc: 'Duyệt chấm công' },
          },
          // 'payslip-config': {
          //   page: { title: 'Lương', desc: 'Cấu hình phiếu lương' },
          // },
          payslip: {
            page: { title: 'Lương', desc: 'Lương của tôi' },
          },
        },
        leaverequest: {
          page: { desc: 'Chi tiết ngày nghỉ' },
        },
        createleave: {
          page: {/*title: 'Nghỉ phép',*/ desc: 'Tạo mới ngày nghỉ' },
        },
        appleaverequest: {
          page: { desc: 'Quản lý nghỉ phép' },
        },
        'appleaverequest#1': {
          page: { desc: 'Quản lý nghỉ phép' },
        },
        leave: {
          leavestatistics: {
            page: { title: 'Nghỉ phép', desc: 'Thống kê nghỉ phép' },
          },
        },
        'leave-management': {
          page: { title: 'Nghỉ phép', desc: 'Quản lý nghỉ phép' },
        },
        inoutlog: {
          attendancecode: {
            page: { title: 'Công nhật', desc: 'Quản lý mã chấm công' },
          },
          myinoutlog: {
            page: { title: 'Công nhật', desc: 'Bảng chấm công của tôi' },
          },
          inoutlogdaily: {
            page: { title: 'Công nhật', desc: 'Bảng chấm công công ty' },
          },
          history: {
            page: { title: 'Công nhật', desc: 'Nhật ký ra vào' },
          },
        },
        absent: {
          appabsent: {
            page: {
              title: 'Xác nhận ngày công',
              desc: 'Quản lý xác nhận ngày công',
            },
          },
          'appabsent#approvalabsent': {
            page: {
              title: 'Xác nhận ngày công',
              desc: 'Quản lý xác nhận ngày công',
            },
          },
          'appabsent#myabsent': {
            page: {
              title: 'Xác nhận ngày công',
              desc: 'Quản lý xác nhận ngày công',
            },
          },
          createabsent: {
            page: { desc: 'Tạo mới ngày công' },
          },
          editabsent: {
            page: { desc: 'Chỉnh sửa ngày công' },
          },
          approvalabsent: {
            page: { desc: 'Xét duyệt ngày công' },
          },
          absentstatistics: {
            page: {
              title: 'Xác nhận ngày công',
              desc: 'Thống kê xác nhận ngày công',
            },
          },
          myabsentdetail: {
            page: { desc: 'Chi tiết chấm công' },
          },
          'absent-manage': {
            page: { title: 'Ngày công', desc: 'Quản lý ngày công' },
          },
        },
        ot: {
          page: { title: 'Tăng ca', desc: 'Quản lý tăng ca của tôi' },
          'otrequest#myot': {
            page: { title: 'Tăng ca', desc: 'Quản lý tăng ca của tôi' },
          },
          'otrequest#myotapprove': {
            page: { title: 'Tăng ca', desc: 'Quản lý tăng ca của tôi' },
          },
          otrequest: {
            page: { title: 'Tăng ca', desc: 'Quản lý tăng ca của tôi' },
          },
          detail: {
            page: { desc: 'Duyệt yêu cầu tăng ca' },
          },
          otstatistics: {
            page: { title: 'Tăng ca', desc: 'Thống kê tăng ca' },
          },
          edit: {
            page: { desc: 'Chỉnh sửa yêu cầu tăng ca' },
          },
          createOTRequest: {
            page: { desc: 'Tạo mới yêu cầu tăng ca' },
          },
          myotdetail: {
            page: { desc: 'Chi tiết tăng ca' },
          },
          'ot-management': {
            page: { title: 'Tăng ca', desc: 'Quản lý tăng ca' },
          },
        },
        'user-management': {
          page: { title: 'Nhân sự', desc: 'Quản lý hồ sơ nhân sự' },
          create: {
            page: {/*title: "Nhân sự",*/ desc: 'Tạo mới hồ sơ nhân sự' },
          },
          edit: {
            page: {/*title: 'Nhân sự',*/ desc: 'Chỉnh sửa hồ sơ nhân sự' },
            'create-contract': {
              page: {/*title: 'Nhân sự',*/ desc: 'Thêm mới hợp đồng nhân sự' },
            },
          },
        },
        'employee-contact': {
          page: { title: 'Nhân sự', desc: 'Thông tin nhân sự' },
        },
        'app-mobile': {
          page: { title: 'Nhân sự', desc: 'App Mobile' },
        },
        appusertemplate: {
          page: { title: 'Biểu mẫu nhân sự', desc: 'Quản lý biểu mẫu nhân sự' },
          createtemplate: {
            page: { desc: 'Tạo mới biểu mẫu nhân sự' },
          },
          editemplate: {
            page: { desc: 'Chỉnh sửa biểu mẫu nhân sự' },
          },
        },
        recruitment: {
          listvacancy: {
            page: { title: 'Tuyển dụng', desc: 'Vị trí đang mở tuyển' },
          },
          detail: {
            page: { desc: 'Vị trí đang mở tuyển' },
          },
          forward: {
            page: { desc: 'Chuyển tiếp ứng viên' },
          },
          myrecruitment: {
            page: { desc: 'Yêu cầu tuyển dụng của tôi' },
          },
          interview: {
            page: { desc: 'Phỏng vấn ứng viên' },
          },
          joblist: {
            page: { desc: 'Tin tuyển dụng' },
          },
          jobdetail: {
            page: { desc: 'Tin tuyển dụng' },
          },
          applicantlist: {
            page: { desc: 'Danh sách hồ sơ ứng tuyển' },
          },
        },
        setting: {
          page: {
            // title: 'Loại phòng ban',
            desc: 'Quản lý loại phòng ban',
          },
          departmenttype: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại phòng ban' },
            create: { page: { desc: 'Thêm mới loại phòng ban' } },
            edit: { page: { desc: 'Chỉnh sửa loại phòng ban' } },
          },
          certificationcategory: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại chứng chỉ' },
            create: { page: { desc: 'Thêm mới loại chứng chỉ' } },
            edit: { page: { desc: 'Chỉnh sửa loại chứng chỉ' } },
          },
          degreecategory: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại bằng cấp' },
            create: { page: { desc: 'Thêm mới loại bằng cấp' } },
            edit: { page: { desc: 'Chỉnh sửa loại bằng cấp' } },
          },
          bankmanagement: {
            page: { title: 'cài đặt', desc: 'Cài đặt danh mục ngân hàng' },
            create: { page: { desc: 'Thêm mới danh mục ngân hàng' } },
            edit: { page: { desc: 'Chỉnh sửa danh mục ngân hàng' } },
          },
          dependencycategory: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại người phụ thuộc' },
            create: { page: { desc: 'Thêm mới loại người phụ thuộc' } },
            edit: { page: { desc: 'Chỉnh sửa loại người phụ thuộc' } },
          },
          leavecategory: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại nghỉ phép' },
            create: { page: { desc: 'Thêm mới loại nghỉ phép' } },
            edit: { page: { desc: 'Chỉnh sửa loại nghỉ phép' } },
          },
          contractcategory: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại hợp đồng' },
            create: { page: { desc: 'Thêm mới loại hợp đồng' } },
            edit: { page: { desc: 'Chỉnh sửa loại hợp đồng' } },
          },
          celebrationmanagement: {
            page: { title: 'cài đặt', desc: 'Cài đặt ngày nghỉ lễ' },
            create: { page: { desc: 'Thêm mới ngày nghỉ lễ' } },
            edit: { page: { desc: 'Chỉnh sửa ngày nghỉ lễ' } },
          },
          otcategory: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại tăng ca' },
            create: { page: { desc: 'Thêm mới loại tăng ca' } },
            edit: { page: { desc: 'Chỉnh sửa loại tăng ca' } },
            workingshift: { page: { desc: 'Danh sách ca làm việc' } },
          },
          leaverestday: {
            page: { title: 'cài đặt', desc: 'Cài đặt ngày nghỉ còn lại' },
          },
          position: {
            page: { title: 'cài đặt', desc: 'Cài đặt chức vụ' },
            create: { page: { desc: 'Thêm mới chức vụ' } },
            edit: { page: { desc: 'Chỉnh sửa chức vụ' } },
          },
          positiontype: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại chức vụ' },
            create: { page: { desc: 'Thêm mới loại chức vụ' } },
            edit: { page: { desc: 'Chỉnh sửa loại chức vụ' } },
          },
          userstatus: {
            page: { title: 'cài đặt', desc: 'Cài đặt loại trạng thái' },
            create: { page: { desc: 'Thêm mới loại trạng thái' } },
            edit: { page: { desc: 'Chỉnh sửa loại trạng thái' } },
          },
          timesheet: {
            page: { title: 'Timesheet', desc: 'Quản lý Timesheet' },
            'timesheet#mytimesheet': {
              page: { title: 'Tăng ca', desc: 'Quản lý Timesheet' },
            },
            'timesheet#timesheetapprove': {
              page: { title: 'Tăng ca', desc: 'Quản lý Timesheet' },
            },
            detail: { page: { desc: 'Duyệt Timesheet' } },
          },
          leaveapprovalrule: {
            page: { title: 'cài đặt', desc: 'Cài đặt duyệt nghỉ phép' },
            create: { page: { desc: 'Thêm mới duyệt nghỉ phép' } },
            edit: { page: { desc: 'Chỉnh sửa duyệt nghỉ phép' } },
          },
          timesheetprojects: {
            page: { title: 'cài đặt', desc: 'Quản lý dự án' },
            create: { page: { desc: 'Thêm dự án mới' } },
            edit: { page: { desc: 'Chỉnh sửa dự án' } },
          },
          officetype: {
            page: { title: 'Cài đặt', desc: 'Cài đặt loại văn phòng' },
            create: { page: { desc: 'Thêm mới loại văn phòng' } },
            edit: { page: { desc: 'Chỉnh sửa loại văn phòng' } },
          },
          office: {
            page: { title: 'Cài đặt', desc: 'Cài đặt văn phòng' },
            create: { page: { desc: 'Thêm mới văn phòng' } },
            edit: { page: { desc: 'Chỉnh sửa văn phòng' } },
          },
        },

        workingshift: {
          appworkingshift: {
            page: { title: 'Xác nhận ngày công', desc: 'Ca làm việc' },
          },
          createworkingshift: {
            page: { desc: 'Thêm mới ca làm việc' },
          },
          editworkingshift: {
            page: { desc: 'Chỉnh sửa ca làm việc' },
          },
          appworkingshiftuser: {
            page: { desc: 'Nhân viên' },
          },
        },
      },
      workflow: {
        workitem: {
          page: { title: 'Hồ sơ', desc: 'Nộp hồ sơ' },
        },

        workflowmanagement: {
          page: { title: 'Quy trình', desc: 'Danh sách quy trình' },
        },
        form: {
          create: { page: { desc: 'Thêm dự biểu mẫu' } },
          edit: { page: { desc: 'Chỉnh sửa biểu mẫu' } },
        },
        myrequestitems: {
          page: { title: 'Hồ sơ', desc: 'Hồ sơ của tôi' },
        },
        'signature-management': {
          page: { title: 'Cấu hình', desc: 'Quản lý chữ ký' },
        },
      },
    },

    material: {
      // form controls
      'form-controls': {
        autocomplete: {
          page: { title: 'Auto Complete', desc: '' },
        },
        checkbox: {
          page: { title: 'Checkbox', desc: '' },
        },
        datepicker: {
          page: { title: 'Datepicker', desc: '' },
        },
        radiobutton: {
          page: { title: 'Radio Button', desc: '' },
        },
        formfield: {
          page: { title: 'Form field', desc: '' },
        },
        input: {
          page: { title: 'Input', desc: '' },
        },
        select: {
          page: { title: 'Select', desc: '' },
        },
        slider: {
          page: { title: 'Slider', desc: '' },
        },
        slidertoggle: {
          page: { title: 'Slider Toggle', desc: '' },
        },
      },
      // navigation
      navigation: {
        menu: {
          page: { title: 'Menu', desc: '' },
        },
        sidenav: {
          page: { title: 'Sidenav', desc: '' },
        },
        toolbar: {
          page: { title: 'Toolbar', desc: '' },
        },
      },
      // layout
      layout: {
        card: {
          page: { title: 'Card', desc: '' },
        },
        divider: {
          page: { title: 'Divider', desc: '' },
        },
        'expansion-panel': {
          page: { title: 'Expansion panel', desc: '' },
        },
        'grid-list': {
          page: { title: 'Grid list', desc: '' },
        },
        list: {
          page: { title: 'List', desc: '' },
        },
        tabs: {
          page: { title: 'Tabs', desc: '' },
        },
        stepper: {
          page: { title: 'Stepper', desc: '' },
        },
        'default-forms': {
          page: { title: 'Default Forms', desc: '' },
        },
        tree: {
          page: { title: 'Tree', desc: '' },
        },
      },
      // buttons & indicators
      'buttons-and-indicators': {
        button: {
          page: { title: 'Button', desc: '' },
        },
        'button-toggle': {
          page: { title: 'Button toggle', desc: '' },
        },
        chips: {
          page: { title: 'Chips', desc: '' },
        },
        icon: {
          page: { title: 'Icon', desc: '' },
        },
        'progress-bar': {
          page: { title: 'Progress bar', desc: '' },
        },
        'progress-spinner': {
          page: { title: 'Progress spinner', desc: '' },
        },
      },
      // popups & models
      'popups-and-modals': {
        'bottom-sheet': {
          page: { title: 'Bottom sheet', desc: '' },
        },
        dialog: {
          page: { title: 'Dialog', desc: '' },
        },
        snackbar: {
          page: { title: 'Snackbar', desc: '' },
        },
        tooltip: {
          page: { title: 'Tooltip', desc: '' },
        },
      },
      // Data tables
      'data-table': {
        paginator: {
          page: { title: 'Paginator', desc: '' },
        },
        'sort-header': {
          page: { title: 'Sort header', desc: '' },
        },
        table: {
          page: { title: 'Table', desc: '' },
        },
      },
    },
    forms: {
      page: { title: 'Forms', desc: '' },
    },
    mail: {
      page: { title: 'Mail', desc: 'Mail' },
    },
    ecommerce: {
      customers: {
        page: { title: 'Customers', desc: '' },
      },
      products: {
        edit: {
          page: { title: 'Edit product', desc: '' },
        },
        add: {
          page: { title: 'Create product', desc: '' },
        },
      },
      orders: {
        page: { title: 'Orders', desc: '' },
      },
    },
    'user-management': {
      users: {
        page: { title: 'Users', desc: '' },
      },
      roles: {
        page: { title: 'Roles', desc: '' },
      },
    },
    builder: {
      page: { title: 'Layout Builder', desc: '' },
    },
    header: {
      actions: {
        page: { title: 'Actions', desc: 'Actions example page' },
      },
    },
    profile: {
      page: { title: 'User Profile', desc: '' },
    },
    error: {
      404: {
        page: { title: '404 Not Found', desc: '', subheader: false },
      },
      403: {
        page: { title: '403 Access Forbidden', desc: '', subheader: false },
      },
    },
    wizard: {
      'wizard-1': { page: { title: 'Wizard 1', desc: '' } },
      'wizard-2': { page: { title: 'Wizard 2', desc: '' } },
      'wizard-3': { page: { title: 'Wizard 3', desc: '' } },
      'wizard-4': { page: { title: 'Wizard 4', desc: '' } },
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
