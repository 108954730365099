import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import * as objectPath from 'object-path';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {LayoutConfigService, LayoutRefService} from '@app/core/_base/layout';
import {HtmlClassService} from '../../html-class.service';
import {environment} from 'environments/environment';
import {BehaviorSubject} from 'rxjs';
import {CompanyInfoService} from '@app/api/companyInfo.service';
@Component({
  selector: 'meu-topbar-header',
  templateUrl: './meu-topbar-header.component.html',
  styleUrls: ['./meu-topbar-header.component.scss'],
})
export class MeuTopbarHeaderComponent implements OnInit, AfterViewInit {
  menuHeaderDisplay: boolean;
  fluid: boolean;
  @ViewChild('meuHeader', {static: true}) meuHeader: ElementRef;

  /**
   * Component constructor
   *
   * @param router: Router
   * @param layoutRefService: LayoutRefService
   * @param layoutConfigService: LayoutConfigService
   * @param loader: LoadingBarService
   * @param htmlClassService: HtmlClassService
   */
  constructor(
    private router: Router,
    private layoutRefService: LayoutRefService,
    private layoutConfigService: LayoutConfigService,
    public loader: LoadingBarService,
    public htmlClassService: HtmlClassService,
    private companyInfoService: CompanyInfoService
  ) {
    // page progress bar percentage
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
      }
    });
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();

    // get menu header display option
    this.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');

    // header width fluid
    this.fluid = objectPath.get(config, 'header.self.width') === 'fluid';

    //animate the header minimize the height on scroll down
    if (
      objectPath.get(config, 'header.self.fixed.desktop.enabled') ||
      objectPath.get(config, 'header.self.fixed.desktop')
    ) {
      // header minimize on scroll down
      this.meuHeader.nativeElement.setAttribute('data-ktheader-minimize', '0');
    }
  }

  ngAfterViewInit(): void {
    // keep header element in the service
    this.layoutRefService.addElement('header', this.meuHeader.nativeElement);
  }
}
