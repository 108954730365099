import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AuthService} from '@app/core/auth';

@Directive({selector: '[permission]'})
export class PermissionDirective implements OnInit {
  constructor(private el: ElementRef, private authService: AuthService) {}
  @Input('permission') permission: string;
  ngOnInit() {
    //const lstPermission = this.authService.getMyPermissions();
    this.authService.getMyPermissions().subscribe((lstPermission) => {
      const isHasPermission = lstPermission.includes(this.permission);
      if (!isHasPermission) {
        return this.el.nativeElement.remove();
      }
    });
  }
}
