import { Attribute, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth';

@Directive({ selector: '[maxlength]' })
export class MaxLenghtDirective implements OnInit {
    constructor(private el: ElementRef) {
    }
    element;
    @Input('maxlength') maxlength: string;
    ngOnInit() {
        let str = '<div style="font-size:11px;color:grey;float:right">Tối đa: <span>' + this.maxlength + '</span> kí tự<div>';
        this.el.nativeElement.insertAdjacentHTML('afterend', str);
    }
}