import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FullNameService {
  constructor() {}

  public convertToFullName(
    firstName: string,
    middleName: string,
    lastName: string
  ): string {
    if (middleName && middleName.trim() !== '') {
      return firstName.trim() + ' ' + middleName.trim() + ' ' + lastName.trim();
    }
    return firstName.trim() + ' ' + lastName.trim();
  }
}
