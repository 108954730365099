import { Directive, ElementRef, Input, OnInit, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Directive({ selector: 'mat-paginator' })
export class PaginatorDirective implements OnInit, AfterViewInit, OnChanges {

    constructor(private paginator: MatPaginator, private el: ElementRef) {
    }
    @Input('length') length;
    ngOnChanges(changes: SimpleChanges) {
        if (this.paginator.pageIndex > 0 && this.length <= this.paginator.pageSize * this.paginator.pageIndex) {
            let btnPrevious: HTMLElement = this.el.nativeElement.querySelector(".mat-paginator-navigation-previous");
            btnPrevious.click();
        }
    }
    ngOnInit() {

    }
    ngAfterViewInit() {
        this.paginator._intl.itemsPerPageLabel = "Số dòng trên một trang:";
        this.paginator._intl.firstPageLabel = "Trang đầu";
        this.paginator._intl.lastPageLabel = "Trang cuối";
        this.paginator._intl.nextPageLabel = "Trang sau";
        this.paginator._intl.previousPageLabel = "Trang trước";
    }

}