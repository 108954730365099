import { Component, OnInit } from '@angular/core';
import { ConfirmPasswordValidator } from '../register/confirm-password.validator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '@app/api/users.service';
import { Router } from '@angular/router';
//Toast
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'kt-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  resetPassForm: FormGroup;
  loading = false;
  showNewPass: boolean = false;
  showConfirmPass: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private Toast: ToastrService
  ) { }
  secretkey: string;

  ngOnInit() {
    this.initResetPassForm();
    this.secretkey = window.location.search.replace('?secretkey=', '');
    this.secretkey = decodeURIComponent(this.secretkey.replace(/\%20/gm, '+'));
    this.ValidateSecretKey(this.secretkey);
  }

  initResetPassForm() {
    this.resetPassForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        confirmPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        agree: [false, Validators.compose([Validators.required])],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }
  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetPassForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.resetPassForm.controls;

    // check form
    if (this.resetPassForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
  }

  ValidateSecretKey(key) {
    this.userService
      .apiUsersUsersValidateSecretKeyPost(key)
      .subscribe((res) => {
        if (!res.success) {
          this.router.navigate(['/error/404']);
        }
      });
  }

  setPassword() {
    // check form
    const controls = this.resetPassForm.controls;
    if (this.resetPassForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.userService
      .apiUsersSetPasswordPut(this.resetPassForm.value.password, this.secretkey)
      .subscribe((res) => {
        if (res.success) {
          this.Toast.success(
            'Mật khẩu mới được thiết đặt thành công',
            'Thao tác thành công'
          );
          this.router.navigate(['/auth/login']);
        } else {
          this.Toast.error(
            'Thao tác thiết đặt mật khẩu mới không thành công. Vui lòng thử lại.',
            'Thao tác không thành công',
            { timeOut: 3000 }
          );
        }
      });
  }
}
